import styled from '@emotion/styled'
import { Button, CircularProgress, IconButton } from '@platform-ui/components'
import { CheckIcon } from '@platform-ui/icons'

export const Root = styled.div(({ theme }) => ({
  padding: theme.spacing.s8,
  color: theme.color['text/tertiary'],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const Loader = styled(CircularProgress)(() => ({
  width: '35px',
  height: '35px',
  marginBottom: '10px',
}))

export const SuccessIcon = styled(CheckIcon)(({ theme }) => ({
  width: '35px',
  height: '35px',
  marginBottom: '10px',
  borderRadius: theme.borderRadius.r16,
  backgroundColor: '#25D188',
}))

export const ErrorContent = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: theme.spacing.s4,
  width: '100%',
}))

export const ErrorHeader = styled.div(({ theme }) => ({
  ...theme.typography['headline/16'],
  textAlign: 'left',
  marginBottom: theme.spacing.s2,
}))

export const ErrorMessage = styled.div(({ theme }) => ({
  ...theme.typography['body/14'],
}))

export const RetryButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing.s8,
}))

export const CloseButton = styled(IconButton)(() => ({
  padding: 0,
}))
