import { forwardRef } from 'react'
import {
  CloseButton,
  ErrorContent,
  ErrorHeader,
  Loader,
  RetryButton,
  Root,
  SuccessIcon,
} from './styled'
import { Body14 } from '@platform-ui/typography'
import { ClearIcon } from '@platform-ui/icons'

interface ConnectCategoriesResultProps {
  header: string
  text: string
  isLoading: boolean
  isError: boolean
  showRetryButton: boolean
  onRetryClick?: () => void
  onClose?: () => void
}

export const ConnectCategoriesResult = forwardRef<HTMLDivElement, ConnectCategoriesResultProps>(
  (props, ref) => {
    const { header, text, isLoading, isError, showRetryButton, onRetryClick, onClose } = props

    if (isLoading) {
      return (
        <Root ref={ref}>
          <Loader color="text/tertiary" />
          <div>{header}</div>
        </Root>
      )
    }

    if (isError) {
      return (
        <Root ref={ref}>
          <ErrorContent>
            <div>
              <ErrorHeader>{header}</ErrorHeader>
              <Body14>{text}</Body14>
            </div>
            <CloseButton onClick={onClose}>
              <ClearIcon color="text/tertiary" size="s16" />
            </CloseButton>
          </ErrorContent>
          {showRetryButton && (
            <RetryButton isFullWidth sizeLg="m" sizeMd="m" sizeSm="m" onClick={onRetryClick}>
              Попробовать снова
            </RetryButton>
          )}
        </Root>
      )
    }
    return (
      <Root ref={ref}>
        <SuccessIcon color="text/tertiary" />
        <span>{header}</span>
      </Root>
    )
  }
)
